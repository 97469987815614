import { MCUItem } from "@/types/mcu";
import styles from "./FirstStepTable.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getLastTraceId, getSelectedRow } from "@/utils/selectors";
import { Table } from "@/components/table/Table";
import { TableRow } from "@/components/table/components/tableRow/TableRow";
import { TableCell } from "@/components/table/components/tableCell/TableCell";
import { labels } from "@/utils/labels";
import { resetAllSoftware } from "@/features/software/softwareSlice";
import { add, resetAllTable } from "@/features/table/tableSlice";
import { resetSearch } from "@/features/search/searchSlice";
import React, { useState } from "react";
import Link from "next/link";
import { CheckBox } from "@/components/checkbox/CheckBox";
import classNames from "classnames";
import { Dialog, DialogType } from "@/components/dialog/Dialog";
import { Button } from "@/components/button/Button";
import { ButtonType } from "@/types/types";
import { removeTraceId } from "@/features/traceId/traceIdSlice";
import { setAcceptLicense } from "@/features/license/licenseSlice";

export type FirstStepTableProps = {
  items: MCUItem[];
  title?: string;
  iconName?: string;
  hasMarginBottom?: boolean;
};

export const FirstStepTable = ({
  items,
  title,
  iconName,
  hasMarginBottom,
}: FirstStepTableProps) => {
  const selectedRow = useSelector(getSelectedRow);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const lastTraceId = useSelector(getLastTraceId);

  const handleCheck = (row: MCUItem) => {
    const { seriesId, seriesName, seriesDescription, seriesSTInformationLink } =
      row;

    if (selectedRow) {
      setIsOpen(true);
    } else {
      dispatch(
        add({
          seriesId: seriesId,
          seriesName: seriesName,
          seriesDescription: seriesDescription,
          seriesSTInformationLink: seriesSTInformationLink,
        })
      );
    }

    dispatch(setAcceptLicense(false));
  };

  return (
    <>
      <Table
        title={title}
        iconName={iconName}
        hasMarginBottom={hasMarginBottom}
        hasFooter={!selectedRow}
      >
        <TableRow>
          <TableCell size={15} isSubtitle>
            {labels.SERIES}
          </TableCell>
          <TableCell size={85} isSubtitle>
            {labels.DESCRIPTION}
          </TableCell>
        </TableRow>
        {items &&
          items.map((row, index) => {
            const {
              seriesId,
              seriesName,
              seriesDescription,
              seriesSTInformationLink,
            } = row;

            return (
              <React.Fragment key={seriesName + "_" + index}>
                <TableRow>
                  <>
                    <TableCell size={15} hasBorderRight>
                      <p className={styles.seriesCode}>
                        {seriesName.split(" ")[0]}
                      </p>
                    </TableCell>
                    <TableCell size={55} hasBorderRight>
                      <p className={styles.descriptionText}>
                        {seriesDescription}
                      </p>
                    </TableCell>
                    <TableCell size={15} hasBorderRight centerContent>
                      <span
                        className={classNames(styles.moreInfo, {
                          [styles.selected]: !!selectedRow,
                        })}
                      >
                        {selectedRow ? (
                          <p>{labels.SELECTED}</p>
                        ) : (
                          <Link
                            target="_blank"
                            href={seriesSTInformationLink}
                            rel="noopener noreferrer"
                          >
                            {labels.MORE_INFO}
                          </Link>
                        )}
                      </span>
                    </TableCell>
                    <TableCell size={15} centerContent>
                      <CheckBox
                        id={seriesId}
                        checked={
                          selectedRow?.seriesName.split(" ")[0] ===
                          seriesName.split(" ")[0]
                        }
                        onChangeFunction={() => handleCheck(row)}
                        onlyLabel={!selectedRow}
                        isEmpty={!!selectedRow}
                      >
                        {selectedRow?.seriesName.split(" ")[0] ===
                        seriesName.split(" ")[0]
                          ? labels.UNSELECT
                          : labels.SELECT}
                      </CheckBox>
                    </TableCell>
                  </>
                </TableRow>
              </React.Fragment>
            );
          })}
      </Table>
      <Dialog
        type={DialogType.GENERAL_SDK}
        open={isOpen}
        onOpenChange={setIsOpen}
        title={labels.HARDWARE_REMOVAL}
      >
        <>
          <div className={styles.textWrapper}>
            <p className={styles.paragraph}>
              {labels.UNSELECT_HARDWARE_MODAL_1}
            </p>
            <p className={styles.paragraph}>
              {labels.UNSELECT_HARDWARE_MODAL_2}
            </p>
          </div>
          <div className={styles.footer}>
            <Button
              buttonText="Cancel"
              onClick={() => setIsOpen(false)}
              type={ButtonType.LINK}
            ></Button>
            <Button
              buttonText={labels.UNSELECT_HARDWARE}
              onClick={() => {
                dispatch(resetAllTable());
                dispatch(resetSearch());
                dispatch(resetAllSoftware());
                dispatch(removeTraceId(lastTraceId));
              }}
              type={ButtonType.PRIMARY}
            ></Button>
          </div>
        </>
      </Dialog>
    </>
  );
};
