import classNames from "classnames";
import styles from "./Title.module.scss";

export type TitleProps = {
  hasMarginTop?: boolean;
  hasMarginBottom?: boolean;
  children: React.ReactNode;
};

export const Title = ({
  hasMarginTop,
  hasMarginBottom,
  children,
}: TitleProps) => {
  return (
    <h1
      className={classNames(styles.container, {
        [styles.marginTop]: hasMarginTop,
        [styles.marginBottom]: hasMarginBottom,
      })}
    >
      {children}
    </h1>
  );
};
