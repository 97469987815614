// ENUM
export enum Route {
  HOME = "/",
  DOWNLOAD = "/download/",
  TOOL = "/admin-tool/tool-download/",
  SECURITY = "/admin-tool/security/",
  USER = "/admin-tool/user-management/",
  HISTORY = "/history/",
}

export enum Env {
  DEV = "development",
  PROD = "production",
}

export enum Size {
  SMALL = "small",
  MEDIUM = "medium",
  BIG = "big",
}

export enum ButtonType {
  PRIMARY = "primary",
  PRIMARY_DARK = "primaryDark",
  SECONDARY = "secondary",
  EMPTY = "empty",
  LINK = "link",
}

export enum PaginationType {
  PREV = "previous",
  NEXT = "next",
}

export enum PermissionType {
  EDIT = "edit",
  VIEW = "view",
}

export enum SearchType {
  PACK = "pack",
  EMAIL = "email",
}

// TYPE
export type ApiError = {
  errorCode: number;
  errorDescription: string;
};

export type SelectOption = {
  id: number;
  value: string;
  isDisable?: boolean;
  isReadOnly?: boolean;
};

export enum SDKStatus {
  AVAILABLE = "available",
  UNAVAILABLE = "unavailable",
  NEEDS_REBUILD = "needs to re build",
}

export enum SDKUpdates {
  UP_TO_DATE = "up to date",
  UPDATE_AVAILABLE = "update available",
}
