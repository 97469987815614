import styles from "./LicenseAgreementButtons.module.scss";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { IconNames } from "@/types/iconNames";
import { downloadFile } from "@/utils/function";
import { labels } from "@/utils/labels";
import { Button } from "@/components/button/Button";
import { setAcceptLicense } from "@/features/license/licenseSlice";
import { ButtonType } from "@/types/types";
import classNames from "classnames";
import { LicenseDialog } from "../../licenseDialog/LicenseDialog";

export type LicenseButtonsProps = {
  isDisabled?: boolean;
};

export const LicenseAgreementButtons = ({
  isDisabled,
}: LicenseButtonsProps) => {
  const dispatch = useDispatch();
  const [openLicenseDialog, setOpenLicenseDialog] = useState<boolean>(false);

  return (
    <>
      <section className={styles.container}>
        <Button
          customClassName={styles.button}
          buttonText={labels.DOWNLOAD_PDF}
          iconName={IconNames.DOWNLOAD_FILE}
          iconColor="var(--color-secondary)"
          iconHeight={16}
          iconWidth={16}
          type={ButtonType.LINK}
          onClick={() =>
            downloadFile("licensepdf/1", "SDK_software_license_agreement.pdf")
          }
        />
        <div className={styles.acceptBtnBox}>
          <button
            type="button"
            className={classNames(styles.button, styles.greyBtn)}
            onClick={() => {
              setOpenLicenseDialog(true);
            }}
          >
            {labels.NO_ACCEPT}
          </button>
          <button
            type="button"
            className={classNames(styles.button, styles.greyBtn, {
              [styles.disabled]: isDisabled,
            })}
            onClick={() => dispatch(setAcceptLicense(true))}
            disabled={isDisabled}
          >
            {labels.ACCEPT}
          </button>
        </div>
      </section>

      <LicenseDialog
        open={openLicenseDialog}
        onOpenChange={() => setOpenLicenseDialog(false)}
        hasCloseBtn
        onSetOpenDialog={setOpenLicenseDialog}
        title={labels.LICENCE_REFUSAL}
      />
    </>
  );
};
