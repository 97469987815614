import { RootState } from "@/lib/store";
import { LicenseData, LicenseDataArray, LicensesData } from "@/types/license";
import { MCUSeriesResultData } from "@/types/mcu";
import { DownloadedSDK, GenerateSDKData } from "@/types/sdk";
import { SearchResultData } from "@/types/search";
import { SoftwareListData } from "@/types/software";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
    credentials: "same-origin",
    prepareHeaders: (headers, { getState }) => {
      const { traceId, login } = getState() as RootState;

      headers.set("trace-Id", traceId.lastTraceId);

      if (login.bearerToken) {
        headers.set("Authorization", login.bearerToken);
      }

      return headers;
    },
  }),
  tagTypes: ["SubscriptionKeys", "ToolList", "SecurityRoleList", "RoleList"],
  endpoints: (builder) => ({
    getMCUSeries: builder.query<MCUSeriesResultData, void>({
      query: () => ({
        url: "/sdk/api/v1/fe/subclass/series",
        method: "GET",
      }),
    }),
    // NOTE search improvement
    getSearchedValue: builder.query<SearchResultData[], string>({
      query: (rootPartNumber) => ({
        url: `/sdk/api/v1/fe/search?rootpartnumber=${encodeURIComponent(
          rootPartNumber
        )}`,
        method: "GET",
      }),
    }),
    getSoftwareList: builder.query<SoftwareListData, string>({
      query: (seriesId) => ({
        url: `/sdk/api/v1/fe/series/${seriesId}`,
        method: "GET",
      }),
    }),

    // NOTE testo per licenza
    getLicenses: builder.query<LicenseDataArray, LicensesData>({
      query: (license) => ({
        url: "/sdk/api/v1/fe/license",
        method: "POST",
        body: JSON.stringify({
          cube: license.cube,
          listPacks: license.listPacks,
          listFeatures: license.listFeatures, // solo cube 2. Nomi delle feature
          series: license.series, // viene utilizzata per logica di cube 2
        }),
      }),
    }),
    // NOTE Accettazione licenza 1
    getGenerateSDK: builder.query<
      GenerateSDKData,
      {
        softwareList: Array<number>;
      }
    >({
      query: ({ softwareList }) => ({
        url: "/sdk/api/v1/fe/generatesdk",
        method: "POST",
        body: JSON.stringify({
          softwareListIds: softwareList,
        }),
      }),
    }),
    // NOTE download SDK pack
    downloadSDK: builder.query<
      DownloadedSDK,
      {
        traceId: string;
      }
    >({
      query: ({ traceId }) => ({
        url: `/sdk/api/v1/fe/download/${traceId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetMCUSeriesQuery,
  useGetSearchedValueQuery,
  useGetSoftwareListQuery,
  useGetLicensesQuery,
  useGetGenerateSDKQuery,
  useDownloadSDKQuery,
} = apiSlice;
