import { useRouter } from "next/router";
import styles from "./HamburgerMenuItem.module.scss";
import classNames from "classnames";
import Link from "next/link";

export type HamburgerMenuItemProps = {
  text: string;
  path: string;
};

export const HamburgerMenuItem = ({ text, path }: HamburgerMenuItemProps) => {
  const router = useRouter();
  const isActive = `/sdk${router.pathname}/` === path;

  return (
    <Link
      type="button"
      className={classNames(styles.itemButton, {
        [styles.isActive]: isActive,
      })}
      href={`/sdk${path}`}
    >
      {text}
    </Link>
  );
};
