import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface historyState {
  activeItem: any; //TODO sostituire con tipo che arriverà da api
}
// valorizzo stato iniziale
const initialState: historyState = {
  activeItem: null,
};

export const historySlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    //TODO sostituire con tipo che arriverà da api
    setActiveItem: (state, action: PayloadAction<any>) => {
      state.activeItem = action.payload;
    },
    resetAllHistory: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setActiveItem, resetAllHistory } = historySlice.actions;

export default historySlice.reducer;
