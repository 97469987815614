import { Box, Tabs } from "@radix-ui/themes";
import styles from "./SecondStepTable.module.scss";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SecondStepTableTabs } from "./components/SecondStepTableTabs/SecondStepTableTabs";
import {
  getSelectedRow,
  getSelectedSoftwareCube1,
  getSelectedSoftwareCube2,
  getSoftwareList,
} from "@/utils/selectors";
import { useGetSoftwareListQuery } from "@/pages/api/apiSlice";
import { Software } from "@/types/software";
import { Spinner } from "@/components/spinner/Spinner";
import { ErrorRow } from "@/components/errorRow/ErrorRow";
import {
  add,
  setSoftwareList,
  toggle,
} from "@/features/software/softwareSlice";
import { labels } from "@/utils/labels";
import { ApiError } from "@/types/types";

import { SecondStepTableRow } from "./components/SecondStepTableRow/SecondStepTableRow";
import { Table } from "@/components/table/Table";
import { TableRow } from "@/components/table/components/tableRow/TableRow";
import { TableCell } from "@/components/table/components/tableCell/TableCell";
import React from "react";

export type SecondStepTableProps = {
  myProps?: boolean;
};

export const SecondStepTable = ({ myProps }: SecondStepTableProps) => {
  const selectedRow = useSelector(getSelectedRow);
  const selectedSoftwareCube1 = useSelector(getSelectedSoftwareCube1);
  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const softwareList = useSelector(getSoftwareList);

  const dispatch = useDispatch();

  const {
    currentData: softwareData,
    isLoading,
    isFetching,
    isError,
    error: queryError,
  } = useGetSoftwareListQuery(`${selectedRow?.seriesId}`, {
    skip: selectedRow?.seriesId === undefined,
    refetchOnMountOrArgChange: true,
  });

  const includedItems: Software[] | undefined = useMemo(() => {
    return softwareData?.softwareList
      .filter((item) => item.suggested === true)
      .map((item) => ({
        ...item,
        isChecked: true,
      }));
  }, [softwareData]);

  const handleMoveRow = (id: number) => {
    const clickedItem =
      softwareList && softwareList.find((item) => item.softwareId === id);

    if (clickedItem) {
      dispatch(toggle(clickedItem));

      const updatedSeriesList =
        softwareList &&
        softwareList.map((item) => {
          if (item.softwareId === id) {
            return { ...item, isChecked: !item.isChecked };
          }
          return item;
        });

      dispatch(setSoftwareList(updatedSeriesList));
    }
  };

  const handleOnClick = (id: number) => {
    if (handleMoveRow) {
      handleMoveRow(id);
    }
  };

  const handleChangeTab = useCallback(() => {
    if (!softwareData) return;

    const updatedSoftwareList = softwareData.softwareList.map((software) => ({
      ...software,
      isChecked: software.suggested ? true : false,
    }));

    dispatch(setSoftwareList(updatedSoftwareList));
  }, [softwareData]);

  useEffect(() => {
    if (softwareData) {
      if (includedItems) dispatch(add(includedItems));

      dispatch(
        setSoftwareList(
          softwareData.softwareList.map((software) => ({
            ...software,
            isChecked:
              software.suggested ||
              (software.cubeVersion === 1
                ? selectedSoftwareCube1
                : selectedSoftwareCube2
              ).some((item) => item.softwareId === software.softwareId),
          }))
        )
      );
    }
  }, [softwareData]);

  if (isError) {
    if ("data" in queryError) {
      const errorDetails = queryError.data as ApiError;

      return <ErrorRow>{errorDetails.errorDescription}</ErrorRow>;
    }
  }

  return (
    <>
      {isLoading || isFetching ? (
        <div className={styles.spinnerWrapper}>
          <Spinner size="small" />
        </div>
      ) : (
        <Tabs.Root defaultValue="cube1">
          {softwareList &&
            softwareList.some((software) => software.cubeVersion === 2) && (
              <SecondStepTableTabs handleChangeTab={handleChangeTab} />
            )}
          <Box>
            <Tabs.Content value="cube1">
              <Table title={labels.SOFTWARE.toUpperCase()}>
                <TableRow>
                  <TableCell size={20} isSubtitle>
                    {labels.NAME}
                  </TableCell>
                  <TableCell size={50} isSubtitle>
                    {labels.DESCRIPTION}
                  </TableCell>
                  <TableCell size={30} isSubtitle>
                    {labels.VERSION}
                  </TableCell>
                </TableRow>

                {softwareList &&
                  softwareList
                    .filter((item) => item.cubeVersion === 1)
                    .map((serie, index) => {
                      const id = serie.softwareId;
                      const rowIdToString = id.toString();

                      return (
                        <React.Fragment key={id + "_" + index}>
                          <SecondStepTableRow
                            serie={serie}
                            id={id + "_" + index}
                            rowIdToString={rowIdToString}
                            handleOnClick={() => handleOnClick(id)}
                          />
                        </React.Fragment>
                      );
                    })}
              </Table>
            </Tabs.Content>

            <Tabs.Content value="cube2">
              <Table title={labels.FEATURES.toUpperCase()}>
                <TableRow>
                  <TableCell size={20} isSubtitle>
                    {labels.NAME}
                  </TableCell>
                  <TableCell size={50} isSubtitle>
                    {labels.DESCRIPTION}
                  </TableCell>
                  <TableCell size={30} isSubtitle>
                    {labels.VERSION}
                  </TableCell>
                </TableRow>

                {softwareList &&
                  softwareList
                    .filter((item) => item.cubeVersion === 2)
                    .map((serie, index) => {
                      const id = serie.softwareId;
                      const rowIdToString = id.toString();

                      return (
                        <React.Fragment key={id + "_" + index}>
                          <SecondStepTableRow
                            serie={serie}
                            id={id}
                            rowIdToString={rowIdToString}
                            handleOnClick={() => handleOnClick(id)}
                          />
                        </React.Fragment>
                      );
                    })}
              </Table>
            </Tabs.Content>
          </Box>
        </Tabs.Root>
      )}
    </>
  );
};
