import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface stepState {
  activeStep: number;
  completeStep: boolean;
}
// valorizzo stato iniziale
const initialState: stepState = {
  activeStep: 1,
  completeStep: false,
};

export const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    toggleCompleteSteps: (state, action: PayloadAction<boolean>) => {
      state.completeStep = action.payload;
    },
    resetAllStep: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setActiveStep, toggleCompleteSteps, resetAllStep } =
  stepSlice.actions;

export default stepSlice.reducer;
