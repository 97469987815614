import { RootState } from "@/lib/store";
import { createSelector } from "reselect";

export const getSearchState = (state: RootState) => state.search;
export const getTableState = (state: RootState) => state.table;
export const getSoftwareState = (state: RootState) => state.software;
export const getLoginState = (state: RootState) => state.login;
export const getDownloadState = (state: RootState) => state.download;
export const getLicenseState = (state: RootState) => state.license;
export const getPathState = (state: RootState) => state.path;
export const getStepState = (state: RootState) => state.step;
export const getTraceIdState = (state: RootState) => state.traceId;
export const getErrorState = (state: RootState) => state.error;
export const getToolDownloadState = (state: RootState) => state.toolDownload;
export const getUserManagementState = (state: RootState) =>
  state.userManagement;
export const getSecurityRolesState = (state: RootState) => state.securityRoles;
export const getHistoryState = (state: RootState) => state.history;

// NOTE search slice
export const getSearchedValue = createSelector(
  [getSearchState],
  (searchState) => searchState.searchedValue
);

export const getInputSearchValue = createSelector(
  [getSearchState],
  (searchState) => searchState.inputSearchValue
);

export const getSearchError = createSelector(
  [getSearchState],
  (searchState) => searchState.error
);

// NOTE table slice
export const getSelectedRow = createSelector(
  [getTableState],
  (tableState) => tableState.selectedRow
);

export const getTableError = createSelector(
  [getTableState],
  (tableState) => tableState.error
);

// NOTE software slice
export const getSelectedSoftwareCube1 = createSelector(
  [getSoftwareState],
  (softwareState) => softwareState.softwareSelectedCube1
);

export const getSelectedSoftwareCube2 = createSelector(
  [getSoftwareState],
  (softwareState) => softwareState.softwareSelectedCube2
);

export const getActiveTab = createSelector(
  [getSoftwareState],
  (softwareState) => softwareState.activeTab
);

export const getSoftwareBuild = createSelector(
  [getSoftwareState],
  (softwareState) => softwareState.buildPackages
);

export const getSoftwareList = createSelector(
  [getSoftwareState],
  (softwareState) => softwareState.softwareList
);

// NOTE login slice
export const getEmail = createSelector(
  [getLoginState],
  (loginState) => loginState.email
);

export const getUser = createSelector(
  [getLoginState],
  (loginState) => loginState.user
);

export const getIsLoggedIn = createSelector(
  [getLoginState],
  (loginState) => loginState.isLoggedIn
);

export const getBearerToken = createSelector(
  [getLoginState],
  (loginState) => loginState.bearerToken
);

// NOTE download slice
export const getDownloadActive = createSelector(
  [getDownloadState],
  (downloadState) => downloadState.downloadStatus
);

export const getFileNameList = createSelector(
  [getDownloadState],
  (downloadState) => downloadState.fileNameList
);

export const getDownloadItemFileName = createSelector(
  [getDownloadState],
  (downloadState) => downloadState.downloadItemFileName
);

export const getWaitingStatus = createSelector(
  [getDownloadState],
  (downloadState) => downloadState.waitingStatus
);

export const getDownloadQuery = createSelector(
  [getDownloadState],
  (downloadState) => downloadState.downloadQuery
);

// NOTE license slice
export const getAcceptLicense = createSelector(
  [getLicenseState],
  (licenseState) => licenseState.acceptLicense
);

// NOTE path slice
export const getPrevUrl = createSelector(
  [getPathState],
  (urlState) => urlState.prevUrl
);

export const getUrlParams = createSelector(
  [getPathState],
  (urlState) => urlState.urlParams
);

// NOTE step slice
export const getActiveStep = createSelector(
  [getStepState],
  (stepState) => stepState.activeStep
);

export const getCompleteSteps = createSelector(
  [getStepState],
  (stepState) => stepState.completeStep
);

// NOTE traceId slice
export const getLastTraceId = createSelector(
  [getTraceIdState],
  (traceIdState) => traceIdState.lastTraceId
);

export const getTraceIdList = createSelector(
  [getTraceIdState],
  (traceIdState) => traceIdState.traceIds
);

export const getClickedTraceId = createSelector(
  [getTraceIdState],
  (traceIdState) => traceIdState.clickedTraceId
);

// NOTE error slice
export const getErrorCode = createSelector(
  [getErrorState],
  (errorState) => errorState.errorCode
);

export const getErrorDescription = createSelector(
  [getErrorState],
  (errorState) => errorState.errorDescription
);

// NOTE tool download slice
export const getSelectedTool = createSelector(
  [getToolDownloadState],
  (toolDownloadState) => toolDownloadState.selectedTool
);

export const getToolList = createSelector(
  [getToolDownloadState],
  (toolDownloadState) => toolDownloadState.toolList
);

export const getToolListTableError = createSelector(
  [getToolDownloadState],
  (toolDownloadState) => toolDownloadState.toolTableError
);

// NOTE security roles slice
export const getSelectedRole = createSelector(
  [getSecurityRolesState],
  (securityRolesState) => securityRolesState.selectedRole
);

export const getSecurityRolesTableError = createSelector(
  [getSecurityRolesState],
  (securityRolesState) => securityRolesState.roleTableError
);

// NOTE userManagement slice
export const getSelectedUsers = createSelector(
  [getUserManagementState],
  (userManagementState) => userManagementState.selectedUser
);

export const getSearchedUsers = createSelector(
  [getUserManagementState],
  (userManagementState) => userManagementState.searchedUsers
);

export const getInputUserSearchValue = createSelector(
  [getUserManagementState],
  (userManagementState) => userManagementState.inputSearchValue
);

export const getUserSearchError = createSelector(
  [getUserManagementState],
  (userManagementState) => userManagementState.searchError
);

export const getUserManagementTableError = createSelector(
  [getUserManagementState],
  (userManagementState) => userManagementState.userTableError
);

// NOTE history slice
export const getActiveItem = createSelector(
  [getHistoryState],
  (historyState) => historyState.activeItem
);
