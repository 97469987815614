import classNames from "classnames";
import styles from "./TableCell.module.scss";

export type TableCellProps = {
  size: number;
  isSubtitle?: boolean;
  hasBorderTop?: boolean;
  hasBorderBottom?: boolean;
  hasBorderLeft?: boolean;
  hasBorderRight?: boolean;
  children?: React.ReactNode;
  centerContent?: boolean;
  customClassName?: string;
};

export const TableCell = ({
  size,
  isSubtitle,
  hasBorderTop,
  hasBorderBottom,
  hasBorderLeft,
  hasBorderRight,
  children,
  centerContent,
  customClassName,
}: TableCellProps) => {
  return (
    <td
      className={classNames(styles.container, customClassName, {
        [styles.tableCell]: !isSubtitle,
        [styles.tableSubtitles]: isSubtitle,
        [styles.borderTop]: hasBorderTop,
        [styles.borderBottom]: hasBorderBottom,
        [styles.borderLeft]: hasBorderLeft,
        [styles.borderRight]: hasBorderRight,
        [styles.center]: centerContent,
      })}
      style={{ width: `${size}%` }}
    >
      {children}
    </td>
  );
};
