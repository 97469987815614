import styles from "./Header.module.scss";
import Image from "next/image";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { backHomeAndCleanState } from "@/utils/function";
import { Login } from "../login/Login";
import { getEmail, getIsLoggedIn } from "@/utils/selectors";
import { AsyncBuilder } from "../asyncBuilder/AsyncBuilder";
import { Route } from "@/types/types";
import { HamburgerMenuButton } from "../hamburgerMenu/components/hamburgerMenuButton/HamburgerMenuButton";

export type HeaderProps = {
  isMenuOpen: boolean;
  onToggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Header = ({ isMenuOpen, onToggleMenu }: HeaderProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <header className={styles.header}>
      <section className={styles.buttonContainer}>
        {isLoggedIn && (
          <HamburgerMenuButton
            isMenuOpen={isMenuOpen}
            onToggleMenu={onToggleMenu}
            additionalClassName={styles.button}
          />
        )}

        <button
          type="button"
          onClick={() => backHomeAndCleanState(router, dispatch)}
          className={styles.logoContainer}
        >
          <Image
            src={`${process.env.NEXT_PUBLIC_API_BASE_URL}/assets/Logo.svg`}
            alt="STMicroelectronics logo"
            width={100}
            height={25}
            loader={() =>
              `${process.env.NEXT_PUBLIC_API_BASE_URL}/assets/Logo.svg?w=100`
            }
          />
        </button>
      </section>
      <div className={styles.btnContainer}>
        {router.pathname !== Route.DOWNLOAD && isLoggedIn && <AsyncBuilder />}
        <Login />
      </div>
    </header>
  );
};
