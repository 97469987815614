import tableReducer from "@/features/table/tableSlice";
import searchReducer from "@/features/search/searchSlice";
import softwareReducer from "@/features/software/softwareSlice";
import loginReducer from "@/features/login/loginSlice";
import downloadReducer from "@/features/download/downloadSlice";
import licenseReducer from "@/features/license/licenseSlice";
import pathReducer from "@/features/path/pathSlice";
import stepReducer from "@/features/step/stepSlice";
import traceIdReducer from "@/features/traceId/traceIdSlice";
import errorReducer from "@/features/error/errorSlice";
import historyReducer from "@/features/history/historySlice";
import toolDownloadReducer from "@/features/toolDownload/toolDownloadSlice";
import userManagementReducer from "@/features/userManagement/userManagementSlice";
import securityRolesReducer from "@/features/securityRoles/securityRolesSlice";
import { apiSlice } from "@/pages/api/apiSlice";
import type { Reducer } from "@reduxjs/toolkit";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";

const persistConfig = {
  key: "root",
  storage, // localStorage, se volessimo usare il sessionStorage dovremmo importare storageSession da redux-persist
  stateReconciles: hardSet as (inboundState: CombinedState) => CombinedState,
  whitelist: [
    "table",
    "software",
    "login",
    "download",
    "license",
    "step",
    "traceId",
    "toolDownload",
    "userManagement",
    "securityRoles",
    "history",
  ],
};

// Combina i tuoi reducer
const rootReducer = combineReducers({
  table: tableReducer,
  search: searchReducer,
  software: softwareReducer,
  login: loginReducer,
  download: downloadReducer,
  license: licenseReducer,
  step: stepReducer,
  path: pathReducer,
  traceId: traceIdReducer,
  error: errorReducer,
  toolDownload: toolDownloadReducer,
  userManagement: userManagementReducer,
  securityRoles: securityRolesReducer,
  history: historyReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

// Estrai il tipo di stato combinato
type CombinedState =
  typeof rootReducer extends Reducer<infer U, any> ? U : never;

// Crea un reducer persistente
const persistedReducer = persistReducer<CombinedState>(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

// Exporta il persistor per essere usato con PersistGate
export const persistor = persistStore(store);

// Funzione per pulire il localStorage dopo un certo periodo di inattività - 20 min
// export const cleanUpLocalStorage = () => {
//   if (isClientSide()) {
//     setTimeout(
//       () => {
//         localStorage.clear();
//         location.reload();
//         window.location.href = "/";
//       },
//       1000 * 60 * 20
//     );
//   }
// };

// cleanUpLocalStorage();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
