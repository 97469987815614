export const labels = {
  MAIN_TITLE: "Build your SDK",
  BUTTON_TEXT: "Build SDK",
  MAIN_PARAGRAPH:
    "Select your microcontroller and your software package. Download your SDK and get started.",
  STEP1_TITLE: "Hardware Selection",
  STEP1_PARAGRAPH: "Select the MCU series from the list below",
  STEP2_TITLE: "Software Items Selection",
  STEP2_PARAGRAPH: "Select the items you want to include",
  STEP3_TITLE: "Software license agreement",
  MORE_INFO: "More info",
  SELECT: "Select pack",
  UNSELECT: "Unselect",
  SERIES: "Series",
  SOFTWARE_PACKAGES: "software packages",
  ADDED: "added",
  PACK: "pack",
  PACKS: "packs",
  REMOVE: "remove",
  ADD_TO_SDK: "add to SDK",
  NAME: "name",
  DESCRIPTION: "description",
  VERSION: "version",
  DOWNLOAD_PDF: "Download PDF",
  NO_ACCEPT: "I do not accept",
  ACCEPT: "I accept",
  CUBE_1: "cube 1",
  CUBE_2: "cube 2",
  LOGIN_MY_ST: "Login to MyST",
  PLEASE_WAIT: "Please wait until the build is completed",
  BUILDING_SDK: "Building your SDK for",
  CANCEL: "Cancel",
  READY_SDK: "Your SDK is ready",
  DOWNLOAD_SDK: "Download SDK",
  BUILD_NOW: "Build it now",
  NEED_NEW_SDK: "Need another SDK?",
  GENERATE_KEY: "generate key",
  SUBSCRIPTION_KEYS: "Subscription keys",
  NEED_TOOLS: "You might also need:",
  DOWNLOAD: "Download",
  FOOTER_RIGHTS: "All rights reserved © 2024 STMicroelectronics",
  LATEST_SDKS: "Latest SDKs",
  PLATFORM: "Platform",
  PACKAGES: "Packages",
  BUILT_ON: "Built on",
  SELECTED: "Selected",
  INCLUDED_DEFAULT: "Included (default)",
  SDK_BUILDER: "SDK builder",
  WAIT: " Please, wait until the build is completed.",
  READY_DOWNLOAD_SDK: "Your SDK is ready for download",
  BUILD_IN_BACKGROUND: "The build is running in background",
  SDK_SELECTION: "Sdk selection",
  CURRENT_SELECTION: "Your current selection:",
  HARDWARE_SELECTION: "Hardware selection",
  FEATURE_SELECTION: "Feature selection",
  HARDWARE_REMOVAL: "Hardware removal",
  UNSELECT_HARDWARE: "Unselect Hardware",
  UNSELECT_HARDWARE_MODAL_1:
    " By removing the hardware, you will have to start from scratch and you will lose the current selection of items added to your SDK.",
  UNSELECT_HARDWARE_MODAL_2:
    "If you are sure to proceed, please click “Unselect Hardware”.",
  BUILD_IN_PROGRESS: "SDK builds in progress:",
  NO_BUILD: "No builds in progress",
  NEXT: "Next",
  TOOLS: "tools",
  POSITION: "position",
  URL: "url",
  EDIT: "Edit",
  ADD_TOOL: "Add New Tool",
  SAVE: "save",
  ROLES: "roles",
  ADD_ROLE: "Add New Role",
  AUTHORIZATION: "authorization",
  SECTION: "section",
  VIEW: "view",
  USERS: "users",
  EMAIL: "email",
  CURRENT_ROLE: "current role",
  ADD_USER: "Add New User",
  USER_ROLE: "user role",
  ADMIN_TOOLS: "admin tools",
  TOOL_DOWNLOAD: "Tool Download List",
  SECURITY_ROLES: "Security Roles",
  USER_MANAGEMENT: "User Management",
  NO_MATCH_FOR: "No match for",
  BACK: "back",
  LICENSE_AGREEMENT: "license agreement",
  EDIT_TOOL: "edit tool",
  EDIT_ROLE: "edit role",
  EDIT_USER: "edit user",
  DELETE_TOOL: "delete tool",
  DELETE_ROLE: "delete role",
  DELETE_USER: "delete user",
  INSERT_NAME: "Insert name",
  INSERT_DESCRIPTION: "Insert description",
  INSERT_URL: "Insert url",
  ITEM_SELECTION: "Item selection",
  VALIDATE_SELECTION: "Validate your selection",
  SOFTWARE: "Software",
  FEATURES: "Features",
  NO_LICENSE_ACCEPT_MODAL:
    "Not accepting the license will return to the previous step without keeping the selection of the previous software",
  LICENCE_REFUSAL: "Licence refusal",
  LIST_OF_BUILD: "List of Build",
  DOWNLOAD_HISTORY_PARAGRAPH_1:
    "Download SDKs until 6 months from their creation date. Re-built an SDK to download it again.",
  DOWNLOAD_HISTORY_PARAGRAPH_2:
    " Unavailable SDKs contains legacy packages (Cube 1) or features (Cube 2), and cannot be re-built.",
  SERIE: "Serie",
  STATUS: "Status",
  UPDATES: "Updates",
  SBOM_LICENSES: "SBOM & Licenses",
  DELETE_SDK: "Delete SDK",
  DOWNLOAD_HISTORY_DETAILS_MODAL_PARAGRAPH:
    "ST always recommends to keeps software up to date to the latest version to ensure the best performance.",
  WARNING: "Warning",
  DOWNLOAD_AS_IS: "Download as-is",
  UPDATE_SDK: "Update SDK",
  HISTORY: "History",
  REBUILD_AS_IS: "Re-build as-is",
  REBUILD_SDK: "Re-build SDK",
  SDK_DETAIL: "SDK Detail",
  LICENSES_PARAGRAPH:
    " Please indicate your acceptance or NON-acceptance by selecting “I ACCEPT” or “I DO NOT ACCEPT” as indicated below in the media.",
};
