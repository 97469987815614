import { onSDKProgressUpdate } from "graphql/subscriptions";

import { NextRouter } from "next/router";
import { v4 as uuidv4 } from "uuid";
import { Dispatch } from "react";
import { UnknownAction } from "@reduxjs/toolkit";
import { setCookie } from "cookies-next";
import { Software } from "@/types/software";
import { Route } from "@/types/types";
import { resetAllSearch } from "@/features/search/searchSlice";
import { resetAllTable } from "@/features/table/tableSlice";
import { resetAllSoftware } from "@/features/software/softwareSlice";
import {
  resetAllLicense,
  setAcceptLicense,
} from "@/features/license/licenseSlice";
import { Amplify } from "aws-amplify";
import { amplifyConfig } from "amplifyconfig";
import { generateClient } from "@aws-amplify/api";
import { setLastTraceId } from "@/features/traceId/traceIdSlice";
import {
  addDownloadQueryItem,
  toggleDownload,
} from "@/features/download/downloadSlice";
import { toggleCompleteSteps } from "@/features/step/stepSlice";

const host = process.env.NEXT_PUBLIC_API_BASE_URL || "";
const baseUrl = `${host}/sdk/api/v1/fe`;

export const makeCamelCase = (value: string): string => {
  return value
    .split(" ")
    .map((e, i) =>
      i ? e.charAt(0).toUpperCase() + e.slice(1).toLowerCase() : e.toLowerCase()
    )
    .join("");
};

export const isClientSide = (): boolean => {
  if (typeof window !== "undefined" && window instanceof Window) return true;

  return false;
};

export const createSoftwareListIds = (
  listSfotwareCube1: Software[],
  listSfotwareCube2: Software[]
): Array<number> => {
  if (listSfotwareCube1.length > listSfotwareCube2.length) {
    return listSfotwareCube1.map((software) => software.softwareId);
  } else if (listSfotwareCube1.length <= listSfotwareCube2.length) {
    return listSfotwareCube2.map((software) => software.softwareId);
  } else {
    return [];
  }
};

export const downloadFile = async (
  endpoint: string,
  fileTitle?: string | undefined,
  isExternal?: boolean
): Promise<void> => {
  try {
    const response = await fetch(
      isExternal ? `${endpoint}` : `${baseUrl}/${endpoint}`,
      {
        method: "GET",
      }
    );
    if (!response.ok) throw new Error(`HTTP error status: ${response.status}`);

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    if (fileTitle) {
      link.setAttribute("download", fileTitle);
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Error while downloading the pdf", error);
  }
};

export const generateUuid = (): string => {
  return uuidv4();
};

export const backHomeAndCleanState = (
  router: NextRouter,
  dispatch: Dispatch<UnknownAction>
): void => {
  router.push(Route.HOME);
  cleanAllStates(dispatch);
};

export const cleanAllStates = (dispatch: Dispatch<UnknownAction>) => {
  // setCookie("userCompletedSteps", false);
  dispatch(resetAllSearch());
  dispatch(resetAllTable());
  dispatch(resetAllSoftware());
  dispatch(resetAllLicense());
  dispatch(setLastTraceId(""));
  dispatch(toggleCompleteSteps(false));
  dispatch(setAcceptLicense(false));
};

export const makeIconName = (name: string) => {
  const stringWithUnderscores = name.replace(/([a-z])([A-Z])/g, "$1_$2");
  const cleanedString = stringWithUnderscores.replace(/^_|_$/g, "");

  return cleanedString.toUpperCase();
};

export const startSubscribe = (
  idList: string[],
  dispatch: Dispatch<UnknownAction>
) => {
  Amplify.configure(amplifyConfig);

  const client = generateClient();
  const subscriptions: any[] = [];

  idList.forEach((id) => {
    const subscription = client
      .graphql({ query: onSDKProgressUpdate, variables: { id } })
      .subscribe({
        next: (response) => {
          console.log("Response subscription", response);
          if (response) {
            dispatch(
              addDownloadQueryItem({ [id]: response.data.onSDKProgressUpdate })
            );
          }
        },

        error: (error) => {
          console.error(`Subscription error for ID ${id}`, error);
        },
      });

    subscriptions.push(subscription);
  });

  return () => {
    subscriptions.forEach((sub) => sub.unsubscribe());
  };
};

export const storeRefreshToken = (token?: string) => {
  if (localStorage) {
    if (token) {
      localStorage.setItem("refresh_token", token);
    } else {
      localStorage.removeItem("refresh_token");
    }
  }
};

export const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

export const removeAllQueryParams = (router: NextRouter): string => {
  const pathname = router.pathname;
  return `${pathname}`;
};
