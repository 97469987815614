import styles from "./TableRow.module.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getSelectedRow } from "@/utils/selectors";

export type TableRowProps = {
  children: React.ReactNode;
  customClassName?: string;
};

export const TableRow = ({ children, customClassName }: TableRowProps) => {
  const selectedRow = useSelector(getSelectedRow);

  return (
    <tr
      className={classNames(styles.container, customClassName, {
        [styles.highlighted]: selectedRow,
      })}
    >
      {children}
    </tr>
  );
};
