import { Dialog, DialogProps, DialogType } from "@/components/dialog/Dialog";
import styles from "./LicenseDialog.module.scss";
import { Button } from "@/components/button/Button";
import { labels } from "@/utils/labels";
import { ButtonType } from "@/types/types";
import { resetAllSoftware } from "@/features/software/softwareSlice";
import { useDispatch } from "react-redux";

export type LicenseDialogProps = Omit<DialogProps, "children"> & {
  onSetOpenDialog: (isOpen: boolean) => void;
};

export const LicenseDialog = ({
  onSetOpenDialog,
  ...props
}: LicenseDialogProps) => {
  const dispatch = useDispatch();

  return (
    <Dialog type={DialogType.GENERAL_SDK} {...props}>
      <article className={styles.container}>
        <p className={styles.text}>
          If you do not accept the listed licenses, you will not be able to
          build your SDK and you will be redirected to the previous step.
        </p>
        <p className={styles.text}>
          If you are sure to proceed, please click “I do not accept”
        </p>
        <section className={styles.btnContainer}>
          <Button
            buttonText={labels.CANCEL}
            type={ButtonType.EMPTY}
            onClick={() => onSetOpenDialog(false)}
          />
          <Button
            buttonText={labels.NO_ACCEPT}
            type={ButtonType.PRIMARY}
            onClick={() => dispatch(resetAllSoftware())}
          />
        </section>
      </article>
    </Dialog>
  );
};
