import styles from "./Layout.module.scss";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "@/types/types";
import {
  getErrorCode,
  getErrorDescription,
  getPrevUrl,
  getEmail,
  getUrlParams,
} from "@/utils/selectors";
import { ErrorBanner } from "../errorBanner/ErrorBanner";
import { cleanAllStates, storeRefreshToken } from "@/utils/function";
import { Footer } from "../footer/Footer";
import { HamburgerMenu } from "../hamburgerMenu/HamburgerMenu";
import { setCookie } from "cookies-next";
import { Header } from "../header/Header";
import { setUrlParams } from "@/features/path/pathSlice";
import {
  useSendAuthCodeMutation,
  useUpsertUserMutation,
} from "@/pages/api/loginSlice";
import {
  setBearerToken,
  setEmail,
  setExpirationTime,
  setIsLoggedIn,
  setUser,
} from "@/features/login/loginSlice";

export type LayoutProps = {
  children: React.ReactNode;
  hasGradientBg?: boolean;
};

export const Layout = ({ children, hasGradientBg }: LayoutProps) => {
  const router = useRouter();

  const dispatch = useDispatch();
  const prevUrl = useSelector(getPrevUrl);
  const errorCode = useSelector(getErrorCode);
  const errorDescription = useSelector(getErrorDescription);
  const userEmail = useSelector(getEmail);
  const urlParams = useSelector(getUrlParams);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDownloadPage, setIsDownloadPage] = useState<boolean>(false);

  const [sendAuthCode, { data: sendAuthCodeData }] = useSendAuthCodeMutation();
  const [upsertUser, { data: upsertUserData }] = useUpsertUserMutation();

  const handleBeforeHistoryChange = (
    url: string,
    { shallow }: { shallow: boolean }
  ) => {
    if (prevUrl === Route.DOWNLOAD) {
      cleanAllStates(dispatch);
    }
  };

  /**
   * @description When router change search if there are some paramethers in the url and set state searchParams whit them
   * Return undefined if there aren't parameters
   */
  useEffect(() => {
    router.events.on("beforeHistoryChange", handleBeforeHistoryChange);

    if (router.isReady) {
      const params = router.query;

      if (Object.keys(params).length !== 0) {
        dispatch(setUrlParams(params));
      }
    }

    return () => {
      router.events.off("beforeHistoryChange", handleBeforeHistoryChange);
    };
  }, [router, prevUrl]);

  useEffect(() => {
    if (router.asPath === Route.DOWNLOAD) {
      setIsDownloadPage(true);
    }
  }, []);

  useEffect(() => {
    if (userEmail) {
      setCookie("userLoggedIn", true);
    } else {
      setCookie("userLoggedIn", false);
    }
  }, [userEmail]);

  /**
   * @description When searchParams is define, it takes parameter 'code' from url and launch the sendAuthCode POST
   */
  useEffect(() => {
    if (urlParams) {
      const authCode = urlParams["code"];

      if (authCode) {
        storeRefreshToken(authCode);
        sendAuthCode(authCode);
      }
    }
  }, [urlParams]);

  /**
   * @description if sendAuthCode POST return a response, launch the upsertUser POST and save the bearer token
   */
  useEffect(() => {
    if (sendAuthCodeData) {
      dispatch(setExpirationTime(sendAuthCodeData.expires_in));
      dispatch(setIsLoggedIn(true));
      const idToken = sendAuthCodeData.id_token;
      const tokenType = sendAuthCodeData.token_type;
      dispatch(setBearerToken(tokenType + " " + idToken));
      upsertUser();
    }
  }, [sendAuthCodeData]);

  /**
   * @description if upsertUser POST return a response, save the user data in the store
   */
  useEffect(() => {
    if (upsertUserData) {
      dispatch(setEmail(upsertUserData.userId));
      dispatch(
        setUser(`${upsertUserData.givenName} ${upsertUserData.familyName}`)
      );
    }
  }, [upsertUserData]);

  return (
    <>
      <Header isMenuOpen={isMenuOpen} onToggleMenu={setIsMenuOpen} />
      <main
        className={classNames(styles.container, {
          [styles.gradientBg]: hasGradientBg,
          [styles.dFlex]: isDownloadPage,
        })}
      >
        <HamburgerMenu isOpen={isMenuOpen} />
        <div className={styles.wrapper}>
          {errorCode && (
            <ErrorBanner isVisible={true} errorCode={errorCode} hasCloseAction>
              {errorDescription}
            </ErrorBanner>
          )}
          {children}
        </div>
      </main>
      <Footer />
    </>
  );
};
